<template>
  <div>
    <div class="info-table">
      <div style="display: flex">
        <span class="inner-title">夹层信息{{ isReadonly ? '' : '录入' }}</span>
      </div>
      <div style="margin: 10px 0 0 10px">
        <div v-show="!isReadonly">
          <el-button
            size="mini"
            type="primary"
            class="commonBtn"
            @click="aorticDissectionInsert"
            :disabled="signed_status"
          >
            新增
          </el-button>
          <el-button
            size="mini"
            type="warning"
            class="commonBtn"
            @click="aorticDissectionModify"
            :disabled="signed_status"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="danger"
            class="commonBtn"
            @click="aorticDissectionDelete"
            :disabled="signed_status"
          >
            删除
          </el-button>
        </div>

        <el-table
          class="inner-table"
          highlight-current-row
          :data="aorticDissectionTableData"
          :header-cell-style="{ padding: 0 }"
          height="200px"
          style="width: 99%"
          @row-click="_rowClick"
          @row-dblclick="_doubleModify"
          border
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="proximalBreach" label="夹层近端破口位置（分区）"></el-table-column>
          <el-table-column prop="interlayerClassification" label="夹层分类"></el-table-column>
          <el-table-column prop="mezzanineStage" label="夹层分期"></el-table-column>
          <el-table-column prop="deBakey" label="DeBakey分型"></el-table-column>
          <el-table-column prop="stanford" label="Stanford分型"></el-table-column>
          <el-table-column
            prop="intercalationInvolvement"
            label="夹层累及分支动脉"
          ></el-table-column>
          <el-table-column prop="pseudolumen" label="假腔供血动脉"></el-table-column>
          <el-table-column prop="proximalPosition" label="近端锚定区位置"></el-table-column>
          <el-table-column prop="proximalDiameter" label="近端锚定区直径（mm）"></el-table-column>
          <el-table-column prop="nearestDistance" label="近端锚定区长度（mm）"></el-table-column>
          <el-table-column
            prop="distalArtery"
            label="远端锚定区至最近腹腔分支动脉"
          ></el-table-column>
          <el-table-column
            prop="remoteDistance"
            label="远端锚定区至最近腹腔分支动脉距离（mm）"
          ></el-table-column>
          <el-table-column prop="distalDiameter" label="远端锚定区直径（mm）"></el-table-column>
          <el-table-column
            prop="proximalToDistal"
            label="近端锚定区至远端锚定区长度（mm）"
          ></el-table-column>
          <el-table-column prop="approach" label="入路"></el-table-column>
          <el-table-column prop="femoralArtery" label="股总动脉（最小径）（mm）"></el-table-column>
          <el-table-column
            prop="externalIliacArtery"
            label="髂外动脉（最小径）（mm）"
          ></el-table-column>
          <el-table-column prop="handlingOpinions" label="处理意见"></el-table-column>
          <!-- <el-table-column prop="thisTreatment" label="本次治疗"></el-table-column> -->
        </el-table>
        <aortic-dissection-dialog
          ref="aorticDissectionDialog"
          :isReadonly="isReadonly"
          @save="_dialogSave"
        ></aortic-dissection-dialog>
      </div>
    </div>
    <!-- 造影 -->
    <div
      v-if="
        selectedInfo &&
        selectedInfo.handlingOpinions &&
        selectedInfo.handlingOpinions?.includes('造影')
      "
      class="aneurysm-info-entry"
    >
      <div class="info-table">
        <div style="display: flex">
          <span class="inner-title">造影</span>
        </div>
        <div class="equipment_style" style="margin: 10px 0 0 10px">
          <div class="surgery-information">
            <el-tabs type="border-card">
              <el-tab-pane label="处理结果">
                <div class="radiography-table-left">
                  <div class="input-form">
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">并发症</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_complication"
                        :value.sync="radiographyDetail.radiographyComplication"
                        @update:value="changeComplication"
                      />
                    </div>
                    <div v-if="showNLType" class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">内漏分型</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_internal_leakage_type"
                        :value.sync="radiographyDetail.radiographyInternalLeakage"
                      />
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="锚定区">
                <div class="radiography-table-left">
                  <div class="input-form">
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">主体支架近端位置</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_proximal_position"
                        :value.sync="radiographyDetail.proximalBody"
                      />
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">近端锚定区直径</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="number"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.proximalDiameter"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">
                        远端锚定区至最近腹腔分支动脉
                      </div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_nearest_celiac_branch_artery"
                        :value.sync="radiographyDetail.distalBranchArtery"
                      />
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">远端锚定区直径</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="number"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.distalDiameter"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">
                        支架预覆盖病变长度
                      </div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="number"
                          size="mini"
                          unit="mm"
                          v-model="radiographyDetail.stentLesionLength"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">主体支架入路</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_main_support"
                        :value.sync="radiographyDetail.mainSupport"
                      />
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">造影剂用量</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="number"
                          size="mini"
                          unit="ml"
                          v-model="radiographyDetail.contrastAgent"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="主体支架">
                <div class="radiography-table-right">
                  <div class="header">
                    <div class="title-right" v-if="!isReadonly">
                      <el-button
                        type="primary"
                        size="mini"
                        class="header-button"
                        @click="radiographyInsert"
                      >
                        添加
                      </el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        class="header-button"
                        @click="radiographyDelete"
                      >
                        删除
                      </el-button>
                    </div>
                  </div>
                  <el-table
                    :data="radiographyTableDetail"
                    class="inner-table"
                    highlight-current-row
                    :header-cell-style="{ padding: 0 }"
                    @row-click="_rowClickOther"
                    :key="tableKey"
                    height="200px"
                    style="width: 96%"
                    border
                    :cell-style="{ 'text-align': 'center' }"
                  >
                    <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
                    <el-table-column prop="supportLength" label="支架长度"></el-table-column>
                    <el-table-column prop="supportNumber" label="支架数量"></el-table-column>
                  </el-table>
                  <radiography-dialog
                    ref="radiographyDialog"
                    @save="radiography_dialogSave"
                  ></radiography-dialog>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- 支架植入 -->
    <div
      v-if="
        selectedInfo &&
        selectedInfo.handlingOpinions &&
        selectedInfo.handlingOpinions?.includes('支架植入')
      "
      class="aneurysm-info-entry"
    >
      <div class="info-table">
        <div style="display: flex">
          <span class="inner-title">支架植入</span>
        </div>
        <div class="equipment_style" style="margin: 10px 0 0 10px">
          <div class="surgery-information">
            <el-tabs type="border-card">
              <el-tab-pane label="锚定区">
                <div class="bracket-table-left">
                  <div class="input-form">
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">近端锚定区位置</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_anchoring_area"
                        :value.sync="stentImplantationDetail.proximalPosition"
                      />
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">距破口距离</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="number"
                          size="mini"
                          unit="mm"
                          v-model="stentImplantationDetail.distance"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">主体支架远端位置</div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          v-model="stentImplantationDetail.distalBody"
                        />
                      </div>
                    </div>
                    <div class="input-form-item" x>
                      <div class="input-form-item-label" style="width: 100px">
                        距最近分支动脉距离
                      </div>
                      <div style="margin-left: 15px">
                        <cqt-input
                          :isReadonly="isReadonly"
                          class="input_has_unit"
                          placeholder="请输入"
                          type="text"
                          size="mini"
                          unit="mm"
                          v-model="stentImplantationDetail.nearestDistance"
                        />
                      </div>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">
                        支架是否遮挡分支动脉
                      </div>
                      <cqt-radio
                        :value.sync="stentImplantationDetail.occludedArtery"
                        :isReadonly="isReadonly"
                        style="margin-left: 15px"
                      >
                        <el-radio label="是">是</el-radio>
                        <el-radio label="否">否</el-radio>
                      </cqt-radio>
                    </div>
                    <div class="input-form-item">
                      <div class="input-form-item-label" style="width: 100px">遮挡分支动脉</div>
                      <cqt-select
                        style="margin-left: 15px"
                        :isReadonly="isReadonly"
                        activeUrl="/v1/webconsole/comboData/get/AD_dissection_involving_artery"
                        :value.sync="stentImplantationDetail.occludedBranch"
                      />
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="主体支架">
                <div class="bracket-table-right">
                  <div class="header">
                    <div
                      style="display: flex; align-items: center; margin: 10px 0"
                      class="title-left"
                    >
                      <span>是否重建分支动脉</span>
                      <cqt-radio
                        :value.sync="stentImplantationDetail.reconstructionArtery"
                        :isReadonly="isReadonly"
                        style="margin-left: 15px"
                      >
                        <el-radio label="是" @change="changeReconstructionArtery">是</el-radio>
                        <el-radio label="否" @change="changeReconstructionArtery">否</el-radio>
                      </cqt-radio>
                    </div>
                    <div class="title-right" v-if="!isReadonly">
                      <el-button
                        type="primary"
                        size="mini"
                        class="header-button"
                        @click="stentImplantationInsert"
                        :disabled="reconstructionArtery_status"
                      >
                        添加
                      </el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        class="header-button"
                        @click="stentImplantationDelete"
                        :disabled="reconstructionArtery_status"
                      >
                        删除
                      </el-button>
                    </div>
                  </div>
                  <el-table
                    :data="stentImplantationTableDetail"
                    class="inner-table"
                    highlight-current-row
                    :header-cell-style="{ padding: 0 }"
                    @row-click="_rowClickOther"
                    :key="tableKey"
                    height="200px"
                    style="width: 96%"
                    border
                    :cell-style="{ 'text-align': 'center' }"
                  >
                    <el-table-column prop="reconstruction" label="重建分支动脉"></el-table-column>
                    <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
                    <el-table-column prop="supportLength" label="支架长度"></el-table-column>
                    <el-table-column prop="supportNumber" label="支架数量"></el-table-column>
                  </el-table>
                  <stent-implantation-dialog
                    ref="stentImplantationDialog"
                    @save="stentImplantation_dialogSave"
                  ></stent-implantation-dialog>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AorticDissectionDialog from './AorticDissectionDialog.vue'

import RadiographyDialog from './RadiographyDialog.vue'
import StentImplantationDialog from './StentImplantationDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

export default {
  name: 'AorticDissectionInformation',
  components: {
    AorticDissectionDialog,
    RadiographyDialog,
    StentImplantationDialog,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    allDetail: {
      handler(val) {
        if (val) {
          this._initInfoValue()
        }
      }
    },
    selectedInfo: {
      handler(val) {
        if (val) {
          this.getAllSuggestions(val)
        }
      }
    }
  },
  data() {
    return {
      aorticDissectionTableData: [],
      selectedInfo: null,
      selectedRow: null, // 其他选中信息
      tableKey: null,
      /*------------------*/
      radiographyDetail: {
        proximalBody: null, //主体支架近端位置
        proximalDiameter: null, //近端锚定区直径
        distalBranchArtery: null, //远端锚定区至最近腹腔分支动脉
        distalDiameter: null, //远端锚定区直径
        stentLesionLength: null, //支架预覆盖病变长度
        mainSupport: null, //主体支架入路
        contrastAgent: null, //造影剂用量
        radiographyComplication: null, //并发症
        radiographyInternalLeakage: null, //内漏分型
        radiographyTreatmentMethod: null, //处理方式
        recordDate: null, //记录时间
        recordCode: null, //记录医生Code
        recordName: null //记录医生姓名
      },
      stentImplantationDetail: {
        proximalPosition: null, //近端锚定区位置
        distance: null, //距离
        distalBody: null, //主体支架远端位置
        nearestDistance: null, //近端锚定区长度
        occludedArtery: null, //支架是否遮挡分支动脉
        occludedBranch: null, //遮挡分支动脉
        bracketComplication: null, //并发症
        bracketInternalLeakage: null, //内漏分型
        bracketTreatmentMethod: null, //处理方式
        reconstructionArtery: null //是否重建分支动脉
      },
      bracketInternalLeakageExitActive: require('@/assets/img/nerve/bracketInternalLeakage.png'),
      bracketInternalLeakageIsExitHover: false, //是否悬浮到图标上
      radiographyTableDetail: [], // 造影表格
      stentImplantationTableDetail: [], // 分支动脉表格
      reconstructionArtery_status: true,
      showNLType: false
    }
  },
  created() {},
  methods: {
    async _initInfoValue() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.aorticDissectionMezzanine.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticDissectionMezzanine')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            this.aorticDissectionTableData = res.data?.map((item) => ({
              ...item,
              detailIndex: item.id
            }))
            for (let i = 0; i < this.aorticDissectionTableData.length; i++) {
              const element = this.aorticDissectionTableData[i]
              if (element.handlingOpinions) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },
    aorticDissectionInsert() {
      this.$refs.aorticDissectionDialog.Show()
    },
    aorticDissectionModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条夹层信息')
      if (this.selectedInfo) {
        this.$refs.aorticDissectionDialog.Show(this.selectedInfo)
      }
    },
    aorticDissectionDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条夹层信息')
      this.$confirm('是否删除该夹层信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedInfo.id) {
            const index = this.aorticDissectionTableData.findIndex(
              (item) => item.id === this.selectedInfo.id
            )
            if (index !== -1) {
              this.aorticDissectionTableData.splice(index, 1)
            }
          } else {
            const index = this.aorticDissectionTableData.findIndex(
              (item) => item.ids === this.selectedInfo.ids
            )
            if (index !== -1) {
              this.aorticDissectionTableData.splice(index, 1)
            }
          }
          this.selectedInfo = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _rowClick(row) {
      // if (this.isReadonly) return
      this.selectedInfo = null
      this.selectedInfo = row
    },
    _rowClickOther(row) {
      // if (this.isReadonly) return
      this.selectedRow = null
      this.selectedRow = row
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.aorticDissectionDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.aorticDissectionTableData.length; i++) {
          const element = this.aorticDissectionTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.aorticDissectionTableData[i] = val
            this.$set(this.aorticDissectionTableData, i, this.aorticDissectionTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.aorticDissectionTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.aorticDissectionTableData.length; i++) {
          const element = this.aorticDissectionTableData[i]
          if (val.id === element.id) {
            this.aorticDissectionTableData[i] = val
            this.$set(this.aorticDissectionTableData, i, this.aorticDissectionTableData[i])
            break
          }
        }
      }
    },
    /*--------------------------------------------------*/
    getAllSuggestions(val, type) {
      if (val.handlingOpinions?.includes('造影')) {
        this._getNewRadiography(val, type)
      }
      if (val.handlingOpinions?.includes('支架植入')) {
        this._getNewImplantation(val, type)
      }
    },
    // 造影
    async _getNewRadiography(val, type) {
      if (type === 'init') {
        // 基本信息
        this.radiographyDetail = {
          ...this.$options.data().radiographyDetail
        }
        val.aorticDissectionRadiography = this.radiographyDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticDissectionRadiography')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.aorticDissectionRadiography = res.data.data[0]
                this.radiographyDetail = val.aorticDissectionRadiography
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticDissectionRadiography) {
          this.radiographyDetail = val.aorticDissectionRadiography
        } else {
          this.radiographyDetail = {
            ...this.$options.data().radiographyDetail
          }
          val.aorticDissectionRadiography = this.radiographyDetail
        }
      }
      // 造影表格
      if (type === 'init') {
        this.radiographyTableDetail = []
        val.aorticDissectionMainSupport = this.radiographyTableDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticDissectionMainSupport')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                for (let i = 0; i < res.data.data.length; i++) {
                  const element = res.data.data[i]
                  element.detailIndex = Math.random(0, 1000) + Math.random(0, 1000)
                }
                val.aorticDissectionMainSupport = res.data.data
                this.radiographyTableDetail = val.aorticDissectionMainSupport
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticDissectionMainSupport) {
          this.radiographyTableDetail = val.aorticDissectionMainSupport
        } else {
          this.radiographyTableDetail = this.$options.data().radiographyTableDetail
          val.aorticDissectionMainSupport = this.radiographyTableDetail
        }
      }
    },
    radiographyInsert() {
      if (this.isReadonly) return
      this.$refs.radiographyDialog.Show()
    },
    // 支架（表格）删除
    radiographyDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.radiographyTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.radiographyTableDetail.splice(index, 1)
          }
        } else {
          const index = this.radiographyTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.radiographyTableDetail.splice(index, 1)
          }
        }
        this.selectedRow = {}
        //刷新表格
        this.tableKey = Math.random()
      }
    },
    // 支架（表格）保存
    radiography_dialogSave(val) {
      this.selectedRow = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.radiographyTableDetail.length; i++) {
          const element = this.radiographyTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.radiographyTableDetail[i] = val
            this.$set(this.radiographyTableDetail, i, this.radiographyTableDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.radiographyTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.radiographyTableDetail.length; i++) {
          const element = this.radiographyTableDetail[i]
          if (val.id === element.id) {
            this.radiographyTableDetail[i] = val
            this.$set(this.radiographyTableDetail, i, this.radiographyTableDetail[i])
            break
          }
        }
      }
    },
    async _getNewImplantation(val, type) {
      // 基本信息
      if (type === 'init') {
        this.stentImplantationDetail = {
          ...this.$options.data().stentImplantationDetail
        }
        val.aorticDissectionImplantation = this.stentImplantationDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticDissectionImplantation')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.aorticDissectionImplantation = res.data.data[0]
                this.stentImplantationDetail = val.aorticDissectionImplantation
                if (this.stentImplantationDetail.reconstructionArtery === '是') {
                  this.reconstructionArtery_status = false
                }
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticDissectionImplantation) {
          this.stentImplantationDetail = val.aorticDissectionImplantation
          if (this.stentImplantationDetail.reconstructionArtery === '是') {
            this.reconstructionArtery_status = false
          }
        } else {
          this.stentImplantationDetail = {
            ...this.$options.data().stentImplantationDetail
          }
          val.aorticDissectionImplantation = this.stentImplantationDetail
        }
      }
      // 分支动脉表格
      if (type === 'init') {
        this.stentImplantationTableDetail = []
        val.aorticDissectionReconstrArtery = this.stentImplantationTableDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'aorticDissectionReconstrArtery')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                for (let i = 0; i < res.data.data.length; i++) {
                  const element = res.data.data[i]
                  element.detailIndex = Math.random(0, 1000) + Math.random(0, 1000)
                }
                val.aorticDissectionReconstrArtery = res.data.data
                this.stentImplantationTableDetail = val.aorticDissectionReconstrArtery
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.aorticDissectionReconstrArtery) {
          this.stentImplantationTableDetail = val.aorticDissectionReconstrArtery
        } else {
          this.stentImplantationTableDetail = this.$options.data().stentImplantationTableDetail
          val.aorticDissectionReconstrArtery = this.stentImplantationTableDetail
        }
      }
    },
    stentImplantationInsert() {
      if (this.isReadonly) return

      this.$refs.stentImplantationDialog.Show()
    },
    // 分支动脉 删除
    stentImplantationDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.stentImplantationTableDetail.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.stentImplantationTableDetail.splice(index, 1)
          }
        } else {
          const index = this.stentImplantationTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.stentImplantationTableDetail.splice(index, 1)
          }
        }
      }
      this.selectedRow = {}
      //刷新表格
      this.tableKey = Math.random()
    },
    // 分支动脉 保存
    stentImplantation_dialogSave(val) {
      this.selectedRow = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
          const element = this.stentImplantationTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.stentImplantationTableDetail[i] = val
            this.$set(this.stentImplantationTableDetail, i, this.stentImplantationTableDetail[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.stentImplantationTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
          const element = this.stentImplantationTableDetail[i]
          if (val.id === element.id) {
            this.stentImplantationTableDetail[i] = val
            this.$set(this.stentImplantationTableDetail, i, this.stentImplantationTableDetail[i])
            break
          }
        }
      }
    },
    /* ---------------------------------- --------------------------*/
    changeReconstructionArtery(val) {
      if (this.isReadonly) return
      if (val === '是') {
        this.reconstructionArtery_status = false
      } else {
        this.reconstructionArtery_status = true
      }
    },
    // 内漏
    changeComplication(val) {
      if (val && val.includes('内漏')) {
        this.showNLType = true
      } else {
        this.showNLType = false
        this.radiographyDetail.radiographyInternalLeakage = null
      }
    }
  }
}
</script>

<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
.aneurysm-info-entry {
  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 40%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
  .info-table {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px 15px;
    border-radius: 5px;
    position: relative;
    background-color: #fff !important;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    /deep/.el-table .cell {
      white-space: normal;
    }
    /deep/.top-item-row .item-row span {
      margin: 0 0 0 5px;
    }
    button {
      margin-right: 0.5rem;
    }
  }
  .inner-title {
    position: absolute;
    top: -12px;
    left: 12px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }

  .equipment_style {
    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .input-form-item-row {
      width: 100%;
      margin: 0 0.5rem;
      display: flex;
      justify-content: flex-start;
    }

    .input-form-item {
      min-width: 28%;
      margin: 0.4rem 1.5rem;
      display: flex;
      align-items: center;
      .input_has_unit {
        margin: 0;
      }
    }
    @media screen and (max-width: 1180px) {
      .input-form-item {
        min-width: 45%;
        margin: 0.4rem 1rem;
      }
    }

    .input-form-item-label {
      width: 120px;
    }
  }
}
</style>
