<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="主动脉夹层信息" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <div>
          <span>DeBakey分型</span>
          <div
            @mouseenter="deBakeyIsExitHover = true"
            @mouseleave="deBakeyIsExitHover = false"
            class="menus"
            style="float: right"
          >
            <i class="el-icon-warning" v-show="!deBakeyIsExitHover"></i>
            <i v-show="deBakeyIsExitHover" class="el-icon-warning" slot="reference" />
            <el-popover
              placement="bottom"
              width="550"
              trigger="manual"
              v-model="deBakeyIsExitHover"
            >
              <img :src="deBakeyExitActive" style="width: 550px; height: 300px" />
            </el-popover>
          </div>
        </div>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_DeBakey"
          :value.sync="aorticDissectionTableInfo.deBakey"
        />
      </div>
      <div class="item-row">
        <div>
          <span>Stanford分型</span>
          <div
            @mouseenter="stanfordIsExitHover = true"
            @mouseleave="stanfordIsExitHover = false"
            class="menus"
            style="float: right"
          >
            <i class="el-icon-warning" v-show="!stanfordIsExitHover"></i>
            <i v-show="stanfordIsExitHover" class="el-icon-warning" slot="reference" />
            <el-popover
              placement="bottom"
              width="550"
              trigger="manual"
              v-model="stanfordIsExitHover"
            >
              <img :src="stanfordExitActive" style="width: 550px; height: 660px" />
            </el-popover>
          </div>
        </div>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_Stanford"
          :value.sync="aorticDissectionTableInfo.stanford"
        />
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <div>
          <span>夹层近端破口位置（分区）</span>
          <div
            @mouseenter="proximalBreachIsExitHover = true"
            @mouseleave="proximalBreachIsExitHover = false"
            class="menus"
            style="float: right"
          >
            <i class="el-icon-warning" v-show="!proximalBreachIsExitHover"></i>
            <i v-show="proximalBreachIsExitHover" class="el-icon-warning" slot="reference" />
            <el-popover
              placement="bottom"
              width="400"
              trigger="manual"
              v-model="proximalBreachIsExitHover"
            >
              <img :src="proximalBreachExitActive" style="width: 400px; height: 180px" />
            </el-popover>
          </div>
        </div>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_proximal_breach_location"
          :value.sync="aorticDissectionTableInfo.proximalBreach"
        />
      </div>
      <div class="item-row">
        <span>夹层分期</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_mezzanine_stage"
          :value.sync="aorticDissectionTableInfo.mezzanineStage"
        />
      </div>
      <div class="item-row">
        <div>
          <span>夹层分类</span>
          <div
            @mouseenter="interlayerIsExitHover = true"
            @mouseleave="interlayerIsExitHover = false"
            class="menus"
            style="float: right"
          >
            <i class="el-icon-warning" v-show="!interlayerIsExitHover"></i>
            <i v-show="interlayerIsExitHover" class="el-icon-warning" slot="reference" />
            <el-popover
              placement="bottom"
              width="400"
              trigger="manual"
              v-model="interlayerIsExitHover"
            >
              <img :src="interlayerExitActive" style="width: 400px; height: 180px" />
            </el-popover>
          </div>
        </div>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_interlayer_classification"
          :value.sync="aorticDissectionTableInfo.interlayerClassification"
        />
      </div>
      <div class="item-row">
        <span>夹层累及分支动脉</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_dissection_involving_artery"
          :value.sync="aorticDissectionTableInfo.intercalationInvolvement"
        />
      </div>
      <div class="item-row">
        <span>假腔供血动脉</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_dissection_involving_artery"
          :value.sync="aorticDissectionTableInfo.pseudolumen"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>近端锚定区位置</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_proximal_anchoring_position"
          :value.sync="aorticDissectionTableInfo.proximalPosition"
        />
      </div>
      <div class="item-row">
        <span>近端锚定区直径</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aorticDissectionTableInfo.proximalDiameter"
        />
      </div>
      <div class="item-row">
        <span>近端锚定区长度</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aorticDissectionTableInfo.nearestDistance"
        />
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>远端锚定区至最近腹腔分支动脉</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_nearest_celiac_branch_artery"
          :value.sync="aorticDissectionTableInfo.distalArtery"
        />
      </div>
      <div class="item-row">
        <span style="font-size: 13px">远端锚定区至最近腹腔分支动脉距离</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aorticDissectionTableInfo.remoteDistance"
        />
      </div>
      <div class="item-row">
        <span>远端锚定区直径</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aorticDissectionTableInfo.distalDiameter"
        />
      </div>
      <div class="item-row">
        <span>近端锚定区至远端锚定区长度</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="cm"
          v-model="aorticDissectionTableInfo.proximalToDistal"
        />
      </div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>入路</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AD_approach"
          :value.sync="aorticDissectionTableInfo.approach"
        />
      </div>
      <div class="item-row">
        <span>股总动脉（最小径）</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          v-model="aorticDissectionTableInfo.femoralArtery"
        />
      </div>
      <div class="item-row">
        <span>髂外动脉（最小径）</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          v-model="aorticDissectionTableInfo.externalIliacArtery"
        />
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <el-col v-show="!isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-radio label="择期造影" v-model="handlingOpinions">择期造影</el-radio>
        <el-radio label="择期支架植入" v-model="handlingOpinions">择期支架植入</el-radio>
        <el-radio label="其他治疗" v-model="handlingOpinions">其他治疗</el-radio>
        <el-radio label="本次治疗" v-model="handlingOpinions">本次治疗</el-radio>
      </el-col>
      <el-col v-show="isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="handlingOpinions">
          {{ handlingOpinions }}
        </el-tag>
      </el-col>
      <el-col v-show="!isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <current-checkbox-select
          v-if="handlingOpinions === '本次治疗'"
          v-model="currentTreatment"
          :currentTreatment="this.currentTreatment"
        ></current-checkbox-select>
      </el-col>
      <el-col v-show="isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="currentTreatment">
          {{ currentTreatment }}
        </el-tag>
      </el-col>
    </div>
    <div class="button-panel">
      <el-button
        <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
export default {
  name: 'AorticDissectionDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,

    CqtSelect,
    CqtInput
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      deBakeyExitActive: require('@/assets/img/nerve/deBakey.png'),
      deBakeyIsExitHover: false, //是否悬浮到图标上
      stanfordExitActive: require('@/assets/img/nerve/stanford.png'),
      stanfordIsExitHover: false, //是否悬浮到图标上
      temExitActive: require('@/assets/img/nerve/tem.png'),
      temIsExitHover: false, //是否悬浮到图标上
      proximalBreachExitActive: require('@/assets/img/nerve/proximalBreach.png'),
      proximalBreachIsExitHover: false, //是否悬浮到图标上
      interlayerExitActive: require('@/assets/img/nerve/interlayerClassification.png'),
      interlayerIsExitHover: false, //是否悬浮到图标上
      handlingOpinions: ' ',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: null,
      aorticDissectionTableInfo: {
        deBakey: null, //DeBakey分型
        stanford: null, //Stanford分型
        tem: null, //TEM分型
        proximalBreach: null, //夹层近端破口位置（分区）
        mezzanineStage: null, //夹层分期
        interlayerClassification: null, //夹层分类
        intercalationInvolvement: null, //夹层累及分支动脉
        pseudolumen: null, //假腔供血动脉
        proximalPosition: null, //近端锚定区位置
        proximalDiameter: null, //近端锚定区直径
        nearestDistance: null, //近端锚定区长度
        distalArtery: null, //远端锚定区至最近腹腔分支动脉
        remoteDistance: null, //远端锚定区至最近腹腔分支动脉距离
        distalDiameter: null, //远端锚定区直径
        proximalToDistal: null, //近端锚定区至远端锚定区长度
        approach: null, //入路
        femoralArtery: null, //股总动脉（最小径）
        externalIliacArtery: null, //髂外动脉（最小径）
        handlingOpinions: null, //处理意见
        radiography: null, //造影
        stentImplantation: null //支架植入
      },
      handlingOpinionsInfo: []
    }
  },
  watch: {
    handlingOpinions: {
      handler(val) {
        this.aorticDissectionTableInfo.handlingOpinions = val
        if (this.aorticDissectionTableInfo.handlingOpinions !== '本次治疗') {
          this.aorticDissectionTableInfo.radiography = null
          this.aorticDissectionTableInfo.stentImplantation = null
        }
      }
    },
    currentTreatment: {
      handler(val) {
        this.aorticDissectionTableInfo.handlingOpinions = '本次治疗'

        if (val && val?.length > 0) {
          this.aorticDissectionTableInfo.handlingOpinions = ['本次治疗', ...val?.split('|')].join(
            '+'
          )
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.aorticDissectionTableInfo = {}
      if (bloodVessel) this.aorticDissectionTableInfo = deepClone(bloodVessel)

      let sugs = this.aorticDissectionTableInfo.handlingOpinions?.split('+') || []
      this.handlingOpinions = sugs[0] || ''
      if (sugs?.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }

      this.isShow = true
    },
    Save() {
      if (this.isReadonly) return
      var idx = this.aorticDissectionTableInfo.handlingOpinions?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.aorticDissectionTableInfo.handlingOpinions.substr(0, idx + 1)
        var b = this.aorticDissectionTableInfo.handlingOpinions.substr(idx + 2)
        this.aorticDissectionTableInfo.handlingOpinions = a + b
      }
      this.$emit('save', this.aorticDissectionTableInfo)
      this.Close()
    },

    Close() {
      this.isShow = false
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
